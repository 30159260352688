import styles from './styles.module.css';

const VideoLive = (props) => {
    
    const {
        title,
        text,
        url,
        isPreview,
    } = props;
    
    let urlIframe;
    if (url.indexOf('youtube') != -1) {
        let regexYoutube = /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|(?:v|l(?:ive)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/gi;
        const idVideo = regexYoutube.exec(url)
        urlIframe =  `https://www.youtube.com/embed/${idVideo[1]}`;
    } 
    if (url.indexOf('facebook') != -1) {
        urlIframe = "https://www.facebook.com/plugins/video.php?height=314&href=" + encodeURI(url) + "&show_text=false&t=0";
    }
    if (url.indexOf('livestream.com') != -1) {
        urlIframe = url;
     }
     if (url.indexOf('jwplayer') != -1) {
        urlIframe = url;
     }
     if (url.indexOf('habilitado-ellitoral') != -1) {
        urlIframe = url;
     }

    return (
        <div className={isPreview ? styles['preview-containter'] : styles['container-video']}> 
            <div className={styles['center']}>
                <p>{text}</p>
            </div>
            <div className={styles['embebed-video']}>
                <iframe 
                    src={urlIframe}
                    width="100%"
                    scrolling="no" 
                    frameBorder="0" 
                    allowFullScreen={true}
                    loading="lazy"
                    allow="autoplay; clipboard-write; encrypted-media ; picture-in-picture; web-share;"
                    title={title}
                />
            </div>            
            <div className={styles['center']}>
                <h2>{title}</h2>
            </div>
        </div>
    )
}


/*
<iframe src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Fautoimporta%2Fvideos%2F1215002185969186%2F&show_text=false&width=560&t=0" width="560" height="314" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
*/
export default VideoLive;
